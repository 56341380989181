<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4" md="3">
            <DatePicker v-model="runDate" label="Run Date"></DatePicker>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-select v-model="reqType" :items="reqTypes" item-text="description_en" item-value="request_type" label="Request Type"></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-select v-model="reqStatus" :items="reqStatuses" item-text="LookUpStringE" item-value="LookUpKey" label="Request Status"></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-select v-model="displayOption" :items="displayOptions" item-text="label" item-value="value" label="Display Options"></v-select>
          </v-col>
        </v-row>

        <v-btn color="primary" dark class="mb-2" @click="generateReport">{{$t('View Report')}}</v-btn>
      </v-card-text>
    </v-card>

    <v-card style="margin-top: 25px;" v-if="show">
      <v-card-text>
        <v-row>
          <v-col v-if="displayOption == 'S' || displayOption == 'X'"  cols="12" sm="12" md="12">
            <table border="1">
              <tr>
                <td colspan="4"><h1>Summary</h1></td>
              </tr>
              <tr>
                <td></td>
                <td>Key ATIP Stage</td>
                <td>As of {{ runDate }}</td>
                <td>Average Turnaround Days</td>
              </tr>
              <tr v-for="reportKey in reportKeys" :key="reportKey.id">
                <td>{{ reportKey.id }}</td>
                <td>{{ reportKey.label }}</td>
                <td>{{ stageCount(reportKey.key) }}</td>
                <td>{{ stageTurnAroundDays(reportKey.key) }}</td>
              </tr>
              <tr>
                <td></td>
                <td class="text-right"><h3>Totals:</h3></td>
                <td>{{ totalCount }}</td>
                <td>{{ totalDaysTurnAround }}</td>
              </tr>
            </table>
          </v-col>
          <v-col v-if="displayOption == 'D' || displayOption == 'X'" cols="12" sm="12" md="12">
            <table border="1">
              <tr>
                <td></td>
                <td colspan="18"><h3>Details</h3></td>
              </tr>
              <tr>
                <td rowspan="2">NO</td>
                <td rowspan="2">Request</td>
                <td rowspan="2">Summary</td>
                <td rowspan="2">Request Status</td>
                <td rowspan="2"><div>Date</div><div>Complete</div><div>Received</div></td>
                <td colspan="3">Extension</td>
                <td rowspan="2">Due Date</td>
                <td rowspan="2">Date Closed</td>
                <td colspan="2">Pages</td>
                <td rowspan="2">Sectors</td>
                <td colspan="6">Key Stage Event</td>
              </tr>
              <tr>
                <td>Search</td>
                <td>3rd Pty</td>
                <td>Conslt</td>
                <td>Review</td>
                <td>Release</td>
                <td>Type</td>
                <td>Responsible Person</td>
                <td>Start Date</td>
                <td>Target Date</td>
                <td>End Date</td>
                <td>Turnaround<div>Days</div></td>
              </tr>

              <template v-for="rKey in reportKeys">
                <tr v-bind:key="rKey.id" v-if="report.get(rKey.key)">
                  <td><b>{{ rKey.id }}</b></td>
                  <td colspan="18" class="text-left"><b>{{ rKey.label }}</b></td>
                </tr>
                <tr v-for="row in report.get(rKey.key)" :key="row.request_id">
                  <td></td>
                  <td><a href="#">{{ row.RequestDemande }}</a></td>
                  <td>{{ row.Summary }}</td>
                  <td>{{ row.RequestStatus }}</td>
                  <td>{{ row.DateInfoComplete != null ? row.DateInfoComplete.substring(0, 10) : '' }}</td>
                  <td>{{ row.SearchExt }}</td>
                  <td>{{ row.ThdPtyExt }}</td>
                  <td>{{ row.ConsultExt }}</td>
                  <td>{{ row.DateDue != null ? row.DateDue.substring(0, 10) : '' }}</td>
                  <td>{{ row.DateOfClosure != null ? row.DateOfClosure : '' }}</td>
                  <td>{{ row.PagesReviewed != null ? row.PagesReviewed : 0 }}</td>
                  <td>{{ row.PagesReleased != null ? row.PagesReleased : 0 }}</td>
                  <td>{{ row.Sectors != null ? row.Sectors : '' }}</td>
                  <td>{{ row.EventType }}</td>
                  <td>{{ row.ResponsiblePerson }}</td>
                  <td>{{ row.startDate != null ? row.startDate.substring(0, 10) : '' }}</td>
                  <td>{{ row.targetDate != null ? row.targetDate.substring(0, 10) : '' }}</td>
                  <td>{{ row.stopDate != null ? row.stopDate.substring(0, 10) : '' }}</td>
                  <td>{{ row.EventDaysTurnAround }}</td>
                </tr>
                <tr v-bind:key="rKey.key" v-if="report.get(rKey.key)">
                  <td></td>
                  <td colspan="9" class="text-left"><b>{{ stageCount(rKey.key) }} Totals</b></td>
                  <td><b>{{ stageReview(rKey.key) }}</b></td>
                  <td><b>{{ stageRelease(rKey.key) }}</b></td>
                  <td colspan="6"></td>
                  <td><b>{{ stageTurnAroundDays(rKey.key) }} ({{ Math.round(stageTurnAroundDays(rKey.key) / stageCount(rKey.key) * 100)/100 }})</b></td>
                </tr>
              </template>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DatePicker from "../components/report/DatePicker";
import axcessApi from "@/plugins/axcessApi";
export default {
  watch: {
  },
  name: "atipReport",

  components: {
    DatePicker
  },

  data(){
    return {
      loading: true,
      runDate: new Date().toISOString().substr(0, 10),
      show: false,
      reqType: "%",
      reqTypes: [
        {
          id: 0,
          request_type: "%",
          description_en: "All Request Types"
        }
      ],
      reqStatus: "%",
      reqStatuses: [
        {
          LookUpStringE: "All Request Status",
          LookUpKey: "%"
        }
      ],
      displayOption: "S",
      displayOptions: [
        {
          label: "Summary Only",
          value: "S"
        }, 
        {
          label: "Details Only",
          value: "D"
        }, 
        {
          label: "Summary and Details",
          value: "X"
        }
      ],
      report: [],
      reportKeys: [
        {
          id: 1,
          key: "RETL",
          label: "Retrieval"
        },
        {
          id: 2,
          key: "SCAN",
          label: "Scan"
        },
        {
          id: 3,
          key: "EXTN",
          label: "Extension"
        },
        {
          id: 4,
          key: "CONS",
          label: "Consult"
        },
        {
          id: 5,
          key: "COPY",
          label: "Copy Of"
        },
        {
          id: 6,
          key: "HOLD",
          label: "On Hold - Clarification"
        },
        {
          id: 7,
          key: "IPRA",
          label: "IPRA Review"
        },
        {
          id: 8,
          key: "SECT",
          label: "Sector Review"
        },
        {
          id: 9,
          key: "CPLT",
          label: "Complaint"
        },
        {
          id: 10,
          key: "CORT",
          label: "Federal Court"
        },
        {
          id: 11,
          key: "COMP",
          label: "Closed"
        },
        {
          id: 12,
          key: "OTHR",
          label: "Other"
        }
      ],
      totalCount: 0,
      totalDaysTurnAround: 0
    };
  },

  async mounted() {
    await axcessApi.getRequest("/report/reqtype",{headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      }})
      .then(response => {
        this.reqTypes.push(...response.data);
      });

    await axcessApi.getRequest("/report/reqstatus",{headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      }})
      .then(response => {
        this.reqStatuses.push(...response.data);
      });
  },

  async created(){
    this.loading = false;
  },

  methods: {
    stageCount (key) {
      const data = this.report.get(key);
      if (data)
        return data.length;
      else
        return 0;
    },

    stageReview (key) {
      const data = this.report.get(key);
      if (data) {
        let reviews = 0;
        data.forEach(item => {
          reviews += item.PagesReviewed != null ? item.PagesReviewed : 0;
        });
        return reviews;
      } else return 0;
    },

    stageRelease (key) {
      const data = this.report.get(key);
      if (data) {
        let releases = 0;
        data.forEach(item => {
          releases += item.PagesReleased != null ? item.PagesReleased : 0;
        });
        return releases;
      } else return 0;
    },

    stageTurnAroundDays (key) {
      const data = this.report.get(key);
      if (data) {
        let days = 0;
        data.forEach(item => {
          days += item.EventDaysTurnAround;
        });
        return days;
      } else return 0;
    },

    groupBy (data) {
      const map = new Map();
      data.forEach((item) => {
          const key = item.reportkey;
          const collection = map.get(key);
          if (!collection) {
              map.set(key, [item]);
          } else {
              collection.push(item);
          }
      });

      return map;
    },

    async generateReport() {
      try {
        await axcessApi.postRequest(
          "/report/atip",
          {
            run_date: this.runDate,
            req_type: this.reqType,
            req_status: this.reqStatus
          }
        )
        .then(response => {
          this.report = this.groupBy(response.data);

          this.totalCount = 0;
          this.totalDaysTurnAround = 0;
          this.reportKeys.forEach(item => {
            this.totalCount += this.stageCount(item.key);
            this.totalDaysTurnAround += this.stageTurnAroundDays(item.key);
          });
        });
        this.show = true;
      } catch (error) {
        alert('error message');
        this.errored = true;
        this.show = true;
      }
    }
  }
};
</script>

<style>
</style>